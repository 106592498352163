import React, {useEffect, useState} from "react";
import PresetAguilioBnt from "../PresetAgulioBtn";
import ZoomControl from "./ZoomControl";

export function Mirror({
                           dataItem,
                           isPreset,
                           handleMouseDown,
                           width,
                           isPressed,
                       }) {
    useEffect(() => {
        setTimeout(() => {
            if (isPressed === "true") {
                const btn = document.querySelector(
                    "#auglio-wrapper #auglio-container .auglio-start-iframe-button"
                );
                try {
                    btn.click();
                } catch (error) {
                    //
                }
            } else {
                isPressed = 'true'
            }
        }, 1000);
    }, []);
    return (
        <div className="mirror-wrapper w-full h-full relative overflow-hidden"
             style={{zIndex: "1111"}}>
            <div
                id="auglio-standalone-mirror"
                className="p-0 w-full auglio-standalone-mirror"

            >
                <PresetAguilioBnt
                    isAgulioStarted={isPressed}
                    dataItem={dataItem}
                />
            </div>
        </div>

    );
}
